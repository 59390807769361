<template>
  <div class="org">
    <!--海报-->
    <div class="flex-box">
      <div class="banner" @click="scrollTo('intro')">
        <img v-lazy="require('@/assets/org/banner.jpg')" class="banner-img" />
      </div>
    </div>
    <!--区域导航-->
    <div class="tabs" ref="tabs">
      <div class="contain">
        <div
          v-for="tab in tabs"
          :key="tab.id"
          @click="scrollTo(tab.id)"
          class="tab"
        >
          {{ tab.name }}
        </div>
      </div>
    </div>
    <!--悬浮的区域导航-->
    <div class="tabs fixed" v-if="showTabHeader">
      <div class="contain">
        <div
          v-for="tab in tabs"
          :key="tab.id"
          @click="scrollTo(tab.id)"
          :class="['tab', tab.hover ? 'hover' : '']"
        >
          {{ tab.name }}
        </div>
      </div>
    </div>
    <!--方案介绍-->
    <div class="intro" ref="intro">
      <div class="title-box reveal">
        <div class="title1">心理咨询机构·心理诊所解决方案</div>
        <div class="title2">
          <span style="color: #f05b4f"> 提供多端协同平台 </span
          >，帮助心理咨询机构向校园、企业、政府等重点客户，实现一站式<span
            style="color: #f05b4f"
          >
            智<br />能心理测评、档案管理、培训、讲座、EAP
          </span>
          等综合心理健康服务。
        </div>
      </div>
      <div class="intro-contain">
        <div class="box reveal1">
          <img
            v-lazy="require('@/assets/org/intro-img1.png')"
            class="box-img"
          />
          <div class="box-main">
            <div class="flex">
              <img
                v-lazy="require('@/assets/org/intro-logo1.png')"
                class="logo1"
              />
              <div class="box-title">机构管理端</div>
            </div>
            <div class="box-intro">
              机构可以发起测评，查看和打印报告，管理咨询师、用户档案，自主设定用户在小程序测评端上的测评量表价格，查看销售数据统计等。
            </div>
          </div>
        </div>
        <div class="box reveal2">
          <img
            v-lazy="require('@/assets/org/intro-img2.png')"
            class="box-img"
          />
          <div class="box-main">
            <div class="flex">
              <img
                v-lazy="require('@/assets/org/intro-logo2.png')"
                class="logo2"
              />
              <div class="box-title">用户测评端</div>
            </div>
            <div class="box-intro">
              为机构定制的专属数字门户，显示机构名称、LOGO、地址和客服电话，用户灵活自助测评、预约咨询，沉淀私域流量。
            </div>
          </div>
        </div>
        <div class="box reveal3">
          <img
            v-lazy="require('@/assets/org/intro-img3.png')"
            class="box-img"
          />
          <div class="box-main">
            <div class="flex">
              <img
                v-lazy="require('@/assets/org/intro-logo3.png')"
                class="logo3"
              />
              <div class="box-title">大客户自主管理端</div>
            </div>
            <div class="box-intro">
              是为机构的校园、政企、社区等大客户群体搭建的自主管理测评时间、查看报告、数据统计、预警名单的平台。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--适用场景-->
    <div class="scene" ref="scene">
      <div class="title-box reveal">
        <div class="title1">心理咨询机构·心理诊所应用场景</div>
        <div class="title2">
          采用PC端和微信小程序协同模式，三端协同，为心理咨询师及机构赋能，提供贯<br />穿运营全场景的高效解决方案。
        </div>
      </div>
      <div class="scene-contain">
        <div class="box reveal1">
          <img v-lazy="require('@/assets/org/scene1.png')" class="box-img" />
          <div class="box-main">
            <div class="flex">
              <img
                v-lazy="require('@/assets/org/scene-logo1.png')"
                class="box-logo1"
              />
              <div class="box-title">
                面诊前测评：<br />
                近100专业心理测评量表，扫码即测
              </div>
            </div>
            <div class="box-intro">
              100套量表支持在PC版管理账户自定义价格，自定义首页显示的推荐量表。<br />
              用户手机扫码即可测评，即时生成测评报告同步打印，可在线预约后续咨询。<br />
              支持分销，机构在管理端自由上架咨询师信息，为咨询师设置测评分销二维码。
            </div>
          </div>
        </div>
        <div class="box reveal2">
          <div class="box-main">
            <div class="flex">
              <img
                v-lazy="require('@/assets/org/scene-logo2.png')"
                class="box-logo2"
              />
              <div class="box-title">
                团体测评：<br />
                云端架构，支持100万人批量进行
              </div>
            </div>
            <div class="box-intro">
              学校、企业、社区等大客户群体，按指引组织团测，分配大客户自主管理平台。<br />
              在管理平台自主查看心理健康大数据分析反馈、危机预警名单，管理心理档案。<br />
              机构结合测评大数据，为客户提供报告解读、跟进、辅导、干预等心理服务支撑。
            </div>
          </div>
          <img v-lazy="require('@/assets/org/scene2.png')" class="box-img" />
        </div>
        <div class="box reveal3">
          <img v-lazy="require('@/assets/org/scene3.png')" class="box-img" />
          <div class="box-main">
            <div class="flex">
              <img
                v-lazy="require('@/assets/org/scene-logo3.png')"
                class="box-logo3"
              />
              <div class="box-title">
                线上引流：<br />
                0元获客 轻松增收减负
              </div>
            </div>
            <div class="box-intro">
              平台提供多个免费测评量表、爆款趣味量表，供机构线上传播，沉淀私域流量池。<br />
              机构在公众号/小程序/线下场景举办引流活动，通过免费在线测评，引导用户裂变。<br />
              机构专属用户端小程序将收集意向客户联系方式，方便通过电话精准沟通促进转化。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--专业性说明-->
    <div class="tool" ref="tool">
      <div class="tool-box reveal">
        <div style="margin-bottom: 80px">
          <div class="tool-line reveal"></div>
          <div class="tool-title reveal1">
            国际权威量表，专业临床应用
          </div>
          <div class="tool-intro reveal2">
            采用国际权威通用的量表，常模本土化，结合国内大量临床实践修订，涉及到门诊、医疗机构的量表有50多个，是评测来访者心理全方位指标的有效工具，也是专业心理咨询机构、三甲医院常用的诊断标准。
          </div>
        </div>
        <div>
          <div class="tool-line reveal"></div>
          <div class="tool-title reveal1">
            三端协同，支撑机构运营全场景
          </div>
          <div class="tool-intro reveal2">
            机构管理端，用户测评端，大客户自主管理端，三端协同，为机构提供获客引流、心理测评、团体测评、预约咨询、咨询师管理、分销管理、查看报告、数据分析、用户档案管理、营销跟进、大客户分层管理等运营全场景的服务支持。
          </div>
        </div>
      </div>
    </div>
    <!--评测结果展示-->
    <div class="report" ref="report">
      <div class="title-box reveal">
        <div class="title1">
          面向心理咨询机构不同客户群体需求，输出专属分析报告
        </div>
        <div class="title2">
          生成多维度专业心理分析报告，提供分级危机预警名单
        </div>
      </div>
      <div class="report-contain">
        <div class="box reveal1">
          <div class="box-title title-bg1">
            <div style="background: #fafbff">个人线上测评报告</div>
          </div>
          <div class="box-intro">
            面向个人用户，输出多维度专业心理分析报告，数据项解析指引，更通俗，易读懂。
          </div>
          <img
            v-lazy="require('@/assets/org/report-img1.png')"
            class="box-img1"
          />
        </div>
        <div class="box reveal2">
          <div class="box-title title-bg2">
            <div style="background: #fafbff">诊疗咨询专用报告</div>
          </div>
          <div class="box-intro">
            面诊时供咨询师查阅的专用报告，数据维度全面、排列清晰，支持一键打印。
          </div>
          <img
            v-lazy="require('@/assets/org/report-img2.png')"
            class="box-img2"
          />
        </div>
        <div class="box reveal3">
          <div class="box-title title-bg3">
            <div style="background: #fafbff">团测客户整体报告</div>
          </div>
          <div class="box-intro">
            向批量测评的团体，输出整体综合分析报告，生成动态心理档案，提供预警名单。
          </div>
          <img
            v-lazy="require('@/assets/org/report-img3.png')"
            class="box-img3"
          />
        </div>
        <div class="box reveal4">
          <div class="box-title">
            <div style="background: #fafbff; width: 168px">
              更多专业报告， 持续输出
            </div>
          </div>
          <img
            v-lazy="require('@/assets/org/report-img4.png')"
            class="box-img4"
          />
        </div>
      </div>
    </div>
    <!--申请试用-->
    <div class="test" ref="test">
      <div class="title-box reveal">
        <div class="title1">申请免费试用</div>
        <div class="title2">联系客服，开通账号体验</div>
      </div>
      <div class="test-img">
        <img
          v-lazy="require('@/assets/org/Group 500.png')"
          style="height:600px;width:1200px"
          class="revealImg"
          @click="gotoLogin"
        />
      </div>
    </div>
    <!-- <div style="font-size:35px; width:950px; height:50px; text-align:center; font-weight:bold;" >浙ICP备2022026803号</div> -->

  </div>
</template>

<script>
import scrollReveal from "scrollreveal";
export default {
  name: "org",
  data() {
    return {
      tabs: [
        {
          name: "方案介绍",
          id: "intro",
          hover: false,
        },
        {
          name: "适用场景",
          id: "scene",
          hover: false,
        },
        {
          name: "专业性说明",
          id: "tool",
          hover: false,
        },
        {
          name: "测评结果展示",
          id: "report",
          hover: false,
        },
        {
          name: "申请试用",
          id: "test",
          hover: false,
        },
      ],
      showTabHeader: false,
      scrollReveal: new scrollReveal(),
    };
  },
  mounted() {
    this.getReveal();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll: function() {
      this.$nextTick(() => {
        let top = this.$refs.tabs.getBoundingClientRect().top;
        this.showTabHeader = top <= 0 ? true : false;
        if (top <= 0) {
          this.tabs.forEach((tab) => {
            let tabData = this.$refs[tab.id].getBoundingClientRect();
            if (
              Math.floor(tabData.top) <= 0 &&
              Math.floor(tabData.height + tabData.top) > 0
            ) {
              tab["hover"] = true;
            } else {
              tab["hover"] = false;
            }
          });
        }
      });
    },
    scrollTo: function(name) {
      this.$nextTick(() => {
        this.$refs[name].scrollIntoView({ block: "start", behavior: "smooth" });
      });
    },
    gotoLogin() {
      this.$router.push("/login");
    },
    getReveal() {
      let animation = {
        duration: 1000,
        reset: false,
        mobile: false,
        opacity: 0,
        easing: "ease-in-out",
        enter: "bottom",
        distance: "40px",
      };
      let setReveal = (className, delay) => {
        let revealAnimation = JSON.parse(JSON.stringify(animation));
        revealAnimation["delay"] = delay;
        this.scrollReveal.reveal(className, revealAnimation);
      };
      setReveal(".reveal", 0);
      setReveal(".revealImg", 500);
      setReveal(".revealContent", 800);
      setReveal(".reveal1", 200);
      setReveal(".reveal2", 500);
      setReveal(".reveal3", 800);
      setReveal(".reveal4", 1100);
    },
  },
};
</script>
<style scoped lang="scss">
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.org {
  width: 100%;
  background: #fff;

  .title-box {
    @include flex($direction: column, $justify: center);
    text-align: center;
    color: $title-color;
    .title1 {
      font-size: 48px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .title2 {
      font-size: 24px;
      line-height: 33px;
    }
  }

  .banner {
    @include flex($direction: column);
    width: 100%;
    height: 500px;
    overflow: hidden;
    background-image: url("~@/assets/org/banner-bg.jpg");
    background-size: 100% 500px;
    background-repeat: no-repeat;
    background-color: #fff;

    .banner-img {
      width: 1920px;
      height: 500px;
    }
  }

  .tabs {
    width: 100%;
    height: 60px;
    background: #f0f1f7;
    @include flex($justify: center);
    .contain {
      width: $width;
      height: 100%;
      @include flex($justify: space-around);
      .tab {
        font-size: 16px;
        color: #414246;
      }
    }
  }

  .fixed {
    background-color: #f5f6f8;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    height: 60px;
    .tab {
      position: relative;
      padding: 20px 0;
    }
    .tab::after {
      position: absolute;
      content: "";
      bottom: 1px;
      left: 50%;
      width: 0;
      height: 3px;
      border-radius: 30px;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      background-color: $color;
      -webkit-transition: all 0.25s ease-in-out;
      -o-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
    }
    .tab.hover {
      color: $color;
    }
    .tab:hover {
      color: $color;
    }
    .tab:hover::after {
      width: 100%;
    }
  }

  .intro {
    padding-top: 100px;
    @include flex($direction: column, $justify: center);
    .title-box {
      margin-bottom: 60px;
    }
    .intro-contain {
      width: $width;
      @include flex($justify: space-between);
      .box {
        width: 390px;
        height: 430px;
        border-radius: 4px;
        overflow: hidden;
        background: #fafbff;
        .box-img {
          width: 390px;
          height: 220px;
        }
        .box-main {
          padding: 30px 40px;
          .logo1 {
            width: 52px;
            height: 46px;
            margin-right: 24px;
            flex-shrink: 0;
          }
          .logo2 {
            width: 57px;
            height: 44px;
            margin-right: 20px;
            flex-shrink: 0;
          }
          .logo3 {
            width: 52px;
            height: 41px;
            margin-right: 24px;
            flex-shrink: 0;
          }
          .flex {
            @include flex;
          }
          .box-title {
            color: $title-color;
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
          }
          .box-intro {
            color: $intro-color;
            font-size: 16px;
            line-height: 24px;
            margin-top: 20px;
          }
        }
      }
      .box:hover {
        box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
      }
    }
  }

  .scene {
    padding-top: 100px;
    @include flex($direction: column, $justify: center);
    .title-box {
      margin-bottom: 60px;
    }
    .scene-contain {
      width: $width;
      @include flex($justify: space-between);
      flex-wrap: wrap;
      .box {
        @include flex($justify: space-between);
        width: $width;
        height: 350px;
        border-radius: 4px;
        overflow: hidden;
        background: #fafbff;
        margin-bottom: 20px;
        padding: 40px;
        .box-img {
          width: 480px;
          height: 270px;
        }
        .box-main {
          .flex {
            @include flex();
          }
          .box-title {
            color: $title-color;
            font-size: 24px;
            font-weight: 500;
            line-height: 33px;
            margin-left: 20px;
          }
          .box-logo1 {
            width: 45px;
            height: 50px;
            margin-right: 20rpx;
            flex-shrink: 0;
          }
          .box-logo2 {
            width: 51px;
            height: 49px;
            margin-right: 20rpx;
            flex-shrink: 0;
          }
          .box-logo3 {
            width: 45px;
            height: 44px;
            margin-right: 20rpx;
            flex-shrink: 0;
          }
          .box-intro {
            color: $intro-color;
            font-size: 16px;
            line-height: 30px;
            margin-top: 30px;
          }
        }
      }
      .box:hover {
        box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
      }
    }
  }

  .tool {
    padding-top: 100px;
    @include flex($direction: column, $justify: center);
    background: #fff;
    .tool-box {
      width: $width;
      height: 520px;
      padding: 60px 72px 0 92px;
      background: url("~@/assets/org/tool-bg.jpg");
      background-size: $width 520px;
      .tool-line {
        width: 80px;
        height: 4px;
        background: #ffffff;
        margin-bottom: 30px;
      }
      .tool-title {
        font-size: 40px;
        color: #fff;
        line-height: 30px;
        font-weight: 800;
        margin-bottom: 30px;
      }
      .tool-intro {
        font-size: 20px;
        color: #fff;
        line-height: 30px;
      }
    }
  }

  .report {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;
    .title-box {
      margin-bottom: 60px;
    }
    .report-contain {
      width: $width;
      @include flex($justify: space-between);
      .box {
        @include flex($direction: column);
        width: 288px;
        height: 448px;
        border-radius: 4px;
        background: #fafbff;
        padding: 20px 24px;
        .box-title {
          @include flex($direction: column, $justify: center);
          width: 100%;
          height: 156px !important;
          font-size: 24px;
          color: #596df8;
          font-weight: 500;
          background-color: #fafbff;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 76px 156px !important;
        }
        .title-bg1 {
          background-image: url("~@/assets/org/title-bg1.png");
        }
        .title-bg2 {
          background-image: url("~@/assets/org/title-bg2.png");
        }
        .title-bg3 {
          background-image: url("~@/assets/org/title-bg3.png");
        }
        .box-intro {
          font-size: 16px;
          color: $intro-color;
          line-height: 24px;
        }
        .box-img1 {
          width: 178px;
          height: 178px;
        }
        .box-img2 {
          width: 151px;
          height: 151px;
          margin-top: 20px;
        }
        .box-img3 {
          width: 161px;
          height: 161px;
          margin-top: -10px;
        }
        .box-img4 {
          width: 212px;
          height: 213px;
        }
      }
      .box:hover {
        box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
      }
    }
  }

  .test {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;

    .title-box {
      margin-bottom: 40px;
    }
    .test-img {
      width: $width;
      height: 600px;
    }
  }
}
</style>
